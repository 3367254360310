.App {
  min-height: 100vh;
  background-image: linear-gradient(167.96deg, #8FFA3B 0%, #F2DD21 100%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.App-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  color: #35393D;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
}

.App-slog-container {
  margin-top: 40px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #35393D;
}

@media only screen and (max-width: 600px) {
  .App-slog-container {
    font-size: 30px;
  }
}

.App-slog {
  height: 3rem;
  margin: auto;
  overflow: hidden;
  
  ul {
    margin: 0 0.625rem;
    padding: 0;
    animation: scrollUp 5s infinite;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      list-style: none;
    }
  }
}

$item-count: 5;

@keyframes scrollUp {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 25) - 10%}, #{$i * 25%} {
      transform: translateY((-100% / $item-count) * $i);
    }
  }
}

.Icon-svg {
  width: 30px;
  height: 30px;
}

.App-footer {
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
